import { Component, Prop, VModel } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { IVmodelProps } from '@/interfaces/IVmodelProps'

interface IPerPageSelector {
  perPageOptions: number[]
  totalRows: number
  rowsCount: number
}

@Component
export default class WayupTablePerPageSelector extends tsx.Component<
  IPerPageSelector & IVmodelProps<number>
> {
  @Prop({ type: Array, default: () => [10, 20, 50, 100] })
  readonly perPageOptions!: number[]
  @Prop({ type: Number, required: true })
  readonly totalRows!: number
  @Prop({ type: Number, required: true })
  readonly rowsCount!: number

  @VModel({ type: Number }) selected!: number

  get countText() {
    if (this.rowsCount < this.selected) {
      return `(показано ${this.rowsCount} из ${this.totalRows})`
    }

    return `из ${this.totalRows}`
  }

  protected render() {
    return (
      <div>
        <b-form-select
          value={this.selected}
          onInput={(value: number) => (this.selected = value)}
          options={this.perPageOptions}
          class="page-selector mr-50"
          style="width: fit-content;"
        />
        на странице {this.countText}
      </div>
    )
  }
}
