import { IVmodelProps } from '@/interfaces/IVmodelProps'
import { Component, VModel, Prop, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { Filter } from '../types'
import './style.scoped.scss'
import './style.scss'

interface ISearchProps {
  filter?: Filter<any> | null
  onClearFilter?: () => void
}

@Component
export default class WayupTableSearch extends tsx.Component<
  ISearchProps & IVmodelProps<string>
> {
  @VModel({ type: String })
  searchQuery!: string

  @Prop({ type: Object })
  readonly filter?: Filter<any>

  @Watch('searchQuery')
  onSearchQueryChanged() {
    this.lazyInput = this.searchQuery
  }

  @Watch('lazyInput')
  onInputChanged() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.searchQuery = this.lazyInput
    }, 500)
  }

  lazyInput = ''
  timeout: ReturnType<typeof setTimeout> | null = null

  protected render() {
    return (
      <b-input-group class="searh-bar">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <div class="form-control input-wrapper">
          {this.filter && (
            <b-badge
              variant="primary"
              class="filter cursor-pointer"
              onClick={() => this.$emit('clearFilter')}
            >
              <span>{`${this.filter.field.toString()}: ${
                this.filter.value
              }`}</span>
              <feather-icon icon="XIcon" class="ml-50" />
            </b-badge>
          )}
          <b-form-input
            value={this.lazyInput}
            onInput={(value: string) => (this.lazyInput = value)}
            onBlur={() => (this.searchQuery = this.lazyInput)}
            placeholder="поиск"
            trim
            type="text"
            autocomplete="false"
          />
        </div>
      </b-input-group>
    )
  }
}
