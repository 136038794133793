import { Component, Prop, VModel } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { IVmodelProps } from '@/interfaces/IVmodelProps'

interface IPaginationProps {
  perPage: number
  totalRows: number
}

@Component
export default class WayupTablePagination extends tsx.Component<
  IPaginationProps & IVmodelProps<number>
> {
  @Prop({ type: Number, required: true })
  readonly perPage!: number
  @Prop({ type: Number, required: true })
  readonly totalRows!: number

  @VModel({ type: Number })
  currentPage!: number

  protected render() {
    return (
      <b-pagination
        value={this.currentPage}
        onInput={(value: number) => (this.currentPage = value)}
        first-number
        last-number
        nextClass="next-item"
        prevClass="prev-item"
        total-rows={this.totalRows}
        per-page={this.perPage}
        limit={3}
        class="m-0"
      />
    )
  }
}
