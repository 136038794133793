import { Component, Prop, PropSync } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { Column, Sort } from  '../types'
import './style.scoped.scss'

interface IHeaderProps {
  columns: Column<any>[]
  sort?: Sort<any> | null
}

@Component
export default class WayupTableHeader extends tsx.Component<IHeaderProps> {
  @Prop({ type: Array, required: true })
  readonly columns!: Column<any>[]

  @PropSync('sort', { type: Object || null, default: null })
  _sort?: Sort<any> | null

  get sortClass() {
    return (field: string) => {
      if (!this._sort || this._sort.field !== field) {
        return null
      }

      return { [`sort-${this._sort.dir}`]: true }
    }
  }

  onHeaderClick(header: Column<any>) {
    if (!header.sortable || !header.field) {
      return
    }

    let dir: 'asc' | 'desc' = 'asc'

    if (this._sort?.field === header.field) {
      if (this._sort.dir === 'asc') {
        dir = 'desc'
      } else {
        this.$emit('update:sort', null)
        return
      }
    }
    const sortData: Sort<any> = { field: header.field, dir }

    this.$emit('update:sort', sortData)
  }

  protected render() {
    return (
      <b-thead>
        <b-tr>
          {this.columns.map(header => {
            return (
              <b-th
                class={['p-50', 'align-middle', { clickable: header.sortable }]}
                onClick={()=>this.onHeaderClick(header)}
              >
                <div class="d-flex align-items-center">
                  <span class={['flex-grow-1', { 'pr-50': header.sortable }]}>
                    {header.title !== undefined ? header.title : header.field}
                  </span>

                  {header.sortable && !!header.field && (
                    <div
                      class={[
                        'arrows',
                        this.sortClass(header.field.toString()),
                      ]}
                    >
                      <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 49.656 49.656"
              fill="currentColor"
              xml:space="preserve"
            >
              <g>
                <polygon
                  fill="currentColor"
                  points="48.242,35.122 45.414,37.95 24.828,17.364 4.242,37.95 1.414,35.122 24.828,11.707  "
                />
                <path
                  fill="currentColor"
                  d="M45.414,39.363L24.828,18.778L4.242,39.363L0,35.121l24.828-24.828l24.828,24.828L45.414,39.363z    M24.828,15.95l20.586,20.585l1.414-1.414l-22-22l-22,22l1.414,1.414L24.828,15.95z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 49.656 49.656"
              fill="currentColor"
              xml:space="preserve"
            >
              <g>
                <polygon
                  fill="currentColor"
                  points="48.242,35.122 45.414,37.95 24.828,17.364 4.242,37.95 1.414,35.122 24.828,11.707  "
                />
                <path
                  fill="currentColor"
                  d="M45.414,39.363L24.828,18.778L4.242,39.363L0,35.121l24.828-24.828l24.828,24.828L45.414,39.363z    M24.828,15.95l20.586,20.585l1.414-1.414l-22-22l-22,22l1.414,1.414L24.828,15.95z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
                    </div>
                  )}
                </div>
              </b-th>
            )
          })}
        </b-tr>
      </b-thead>
    )
  }
}
