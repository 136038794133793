import { Component, Prop, Watch, PropSync, Emit } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Header from '@/components/WayupTable/Header'
import Footer from '@/components/WayupTable/Footer'
import Search from '@/components/WayupTable/Search'
import Cell from '@/components/WayupTable/Cell'
import { showError } from '@/helpers/notifications'
import { valueFromPath } from '@/helpers/object'
import {
  Column,
  FetchFunction,
  Filter,
  Sort,
  CellData,
  FooterRow,
} from './types'
import './style.scoped.scss'
import './style.scss'
import FooterRowComponent from './FooterRow'

export type Query = {
  page: number
  perPage: number
  search: string
  sort: Sort | null
  filter: Filter | null
}

interface ITableProps {
  rows?: any[]
  columns?: Column[] | null
  totalRows?: number
  footerRow?: FooterRow
  searchEnabled?: boolean
  paginationEnabled?: boolean
  striped?: boolean
  hover?: boolean
  local?: boolean
  fetchFunction?: FetchFunction<any>
  pending?: boolean
  currentPage?: number
  perPage?: number
  sort?: Sort | null
  search?: string
  filter?: Filter | null
  fetchOnMount?: boolean
  perPageOptions?: number[]

  onQueryChanged?: (query: Query) => void
}

export interface IWayupTableRef {
  refresh: () => Promise<void>
  modifyRows: (fn: (rows: any[]) => any[]) => void
}

@Component
export default class WayupTable extends tsx.Component<ITableProps> {
  declare $scopedSlots: tsx.InnerScopedSlots<{
    default?: CellData<any>
    footer?: CellData<any>
  }>

  @Prop({ type: Array, default: () => [] })
  readonly rows!: any[]
  @Prop({ type: Array })
  readonly columns?: Column<any>[] | null
  @Prop({ type: Number, default: 0 })
  readonly totalRows!: number
  @Prop({ type: Boolean, default: false })
  readonly searchEnabled!: boolean
  @Prop({ type: Boolean, default: false })
  readonly paginationEnabled!: boolean
  @Prop({ type: Boolean, default: true })
  readonly striped!: boolean
  @Prop({ type: Boolean, default: true })
  readonly hover!: boolean
  @Prop({ type: Boolean, default: false })
  readonly local!: boolean
  @Prop({ type: Boolean, default: true })
  readonly fetchOnMount!: boolean
  @Prop({ type: Array, default: () => [10, 20, 50, 100] })
  readonly perPageOptions!: number[]
  @Prop({ type: Object })
  readonly footerRow?: FooterRow

  @Prop({ type: Function })
  readonly fetchFunction?: FetchFunction<any>

  @PropSync('pending', { type: Boolean, default: false })
  _pending!: boolean
  @PropSync('currentPage', { type: Number, default: 1 })
  _currentPage!: number
  @PropSync('perPage', { type: Number, default: 10 })
  _perPage!: number
  @PropSync('sort', { type: Object, default: null })
  _sort!: Sort | null
  @PropSync('search', { type: String, default: '' })
  _search!: string
  @PropSync('filter', { type: Object, default: null })
  _filter!: Filter | null

  autoGeneratedColumns: Column[] | null = null

  initialized = false

  tableKey = 0

  rowsLocal: any[] = []
  totalRowsLocal: number = 0
  pendingLocal: boolean = false
  perPageLocal: number = 10
  currentPageLocal: number = 1
  searchLocal: string = ''
  sortLocal: Sort | null = null
  filterLocal: Filter | null = null

  @Watch('pendingLocal')
  onpendingLocalChanged(value: boolean) {
    this._pending = value
  }
  @Watch('perPageLocal')
  onPerPageLocalChanged(value: number) {
    this._perPage = value
    this.refresh()
  }
  @Watch('currentPageLocal')
  onCurrentPageLocalChanged(value: number) {
    this._currentPage = value
    this.refresh()
  }
  @Watch('searchLocal')
  onSearchLocalChanged(value: string) {
    this._search = value
    this.refresh()
  }
  @Watch('sortLocal')
  onSortLocalChanged(value: Sort | null) {
    this._sort = value
    this.refresh()
  }
  @Watch('filterLocal')
  onFilterLocalChanged(value: Filter | null) {
    this._filter = value
    this.refresh()
  }

  @Watch('currentPage')
  @Emit('onPageChanged')
  onPageChanged(value: number) {
    this.currentPageLocal = value
    if (!this.local) {
      this.pendingLocal = true
    }
  }

  @Watch('perPage')
  @Emit('onPerPageChanged')
  onPerPageChanged(value: number) {
    this.perPageLocal = value
    if (!this.local) {
      this.pendingLocal = true
    }
  }

  @Watch('sort')
  @Emit('onSortChanged')
  onSortChanged(value: Sort<any> | null) {
    this.sortLocal = value
    if (!this.local) {
      this.pendingLocal = true
    }
  }

  @Watch('search')
  @Emit('onSearchChanged')
  onSearchChanged(value: string) {
    this.searchLocal = value
    if (!this.local) {
      this.pendingLocal = true
    }
  }

  @Watch('filter')
  @Emit('onFilterChanged')
  onFilterChanged(value: Filter<any> | null) {
    this.filterLocal = value
    if (!this.local) {
      this.pendingLocal = true
    }
  }

  @Watch('pending')
  onPendingChanged(value: boolean) {
    this.pendingLocal = value
  }

  @Watch('rows')
  onRowsChanged(rows: any[]) {
    this.rowsLocal = rows
    this.tableKey++
    if (!this.local) {
      this.pendingLocal = false
    }
    if (!this.columns) {
      this.generateColumns()
    }
  }

  @Watch('totalRows')
  onTotalRowsChanged(value: number) {
    this.totalRowsLocal = value
  }

  get displayedRows() {
    if (!this.local) {
      return this.rowsLocal
    }

    let displayedRows = []

    if (this.sortLocal) {
      const sortField = this.sortLocal.field.toString()
      const sortDir = this.sortLocal!.dir
      const column = this.currentColumns.find(
        c => c.field === this.sortLocal!.field,
      )!
      let path: string | null = null
      let sortFn: ((value1: any, value2: any) => number) | null = null
      if (typeof column.sortable === 'object') {
        path = column.sortable!.path || null
        sortFn = column.sortable!.sortFn || null
      }

      displayedRows = this.rowsLocal.sort(
        (row1: Record<string, any>, row2: Record<string, any>) => {
          const value1 = path ? valueFromPath(row1, path) : row1[sortField]
          const value2 = path ? valueFromPath(row2, path) : row2[sortField]

          let sort = 0

          if (sortFn) {
            sort = sortFn(value1, value2)
          } else if (column.type === 'date' || column.type === 'dateTime') {
            sort = this.sortDates(value1, value2)
          } else if (typeof value1 === 'string') {
            sort = this.sortStrings(value1, value2)
          } else if (typeof value1 === 'number') {
            sort = this.sortNumbers(value1, value2)
          } else if (typeof value1 === 'boolean') {
            sort = this.sortBoolean(value1, value2)
          }

          if (sortDir === 'desc') {
            sort *= -1
          }

          return sort
        },
      )
    }

    if (this.paginationEnabled) {
      displayedRows = this.rowsLocal.slice(
        this.perPageLocal * (this.currentPageLocal - 1),
        this.currentPageLocal * this.perPageLocal,
      )
    }

    return displayedRows
  }

  get currentColumns() {
    return this.autoGeneratedColumns || this.columns || []
  }

  get values() {
    return (row: Record<string, any>) =>
      this.currentColumns.map(c => {
        return c.field ? row[c.field.toString()] : row[c.customField!]
      })
  }

  get cellData() {
    return (row: object, index: number): Omit<CellData<any>, 'saveFn'> => ({
      row,
      value: this.values(row)[index],
      column: this.currentColumns[index],
    })
  }

  get cellType() {
    return (index: number) => {
      return this.currentColumns[index].type || 'string'
    }
  }

  beforeMount() {
    if (!this.columns) {
      this.generateColumns()
    }
  }

  async mounted() {
    this.perPageLocal = this._perPage
    this.currentPageLocal = this._currentPage
    this.searchLocal = this._search
    this.sortLocal = this._sort
    this.filterLocal = this._filter
    this.totalRowsLocal = this.totalRows
    this.rowsLocal = this.rows

    this.$nextTick(async () => {
      this.initialized = true

      if (this.fetchFunction && this.fetchOnMount) {
        await this.refresh()
      }
    })
  }

  filterTable(row: Record<string, any>, index: number) {
    const column = this.currentColumns[index]

    if (!column.filter) {
      return
    }

    if (!column.field) {
      console.error('Нельзя фильровать таблицу по колонке без поля "field"')
      return
    }

    this.pendingLocal = true

    let value, field

    if (typeof column.filter === 'boolean') {
      field = column.field.toString()
      value = row[field]
    } else {
      field = column.filter.field
      value = valueFromPath(row, column.filter.path)
    }

    if (typeof value === 'string') {
      value = `"${value}"`
    }

    this.filterLocal = { field, value }
  }

  clearFilter() {
    this.filterLocal = null
  }

  generateColumns() {
    if (this.rows.length === 0) {
      return
    }
    this.autoGeneratedColumns = Object.keys(this.rows[0]).map(key => ({
      title: key,
      field: key,
    }))
  }

  async refresh() {
    if (!this.initialized) {
      return
    }

    const query: Query = {
      page: this.currentPageLocal,
      perPage: this.perPageLocal,
      search: this.searchLocal,
      sort: this.sortLocal,
      filter: this.filterLocal,
    }

    this.$emit('queryChanged', query)

    if (!this.fetchFunction) {
      return
    }

    try {
      this.pendingLocal = true
      const { rows, totalRows } = await this.fetchFunction(
        this.currentPageLocal,
        this.perPageLocal,
        this.searchLocal,
        this.sortLocal,
        this.filterLocal,
      )

      this.rowsLocal = rows
      this.totalRowsLocal = totalRows
    } catch (error) {
      console.error(error)

      if ((error as any).message === 'cancel') {
        return
      }

      showError('Ошибка при загрузке данных')
    }

    this.tableKey++
    this.pendingLocal = false
  }

  async modifyRows(fn: (rows: any[]) => any[]) {
    this.rowsLocal = fn(this.rowsLocal as any)
  }

  sortNumbers(value1: number, value2: number) {
    return value1 - value2
  }

  sortStrings(value1: string, value2: string) {
    return value1.localeCompare(value2)
  }

  sortBoolean(value1: boolean, value2: boolean) {
    return value1 === value2 ? 0 : value1 ? 1 : -1
  }

  sortDates(value1: string, value2: string) {
    return new Date(value1).getTime() - new Date(value2).getTime()
  }

  protected render() {
    return (
      <div class="wayup-table-wrapper rounded">
        {this.searchEnabled && (
          <Search
            value={this.searchLocal}
            onInput={(value: string) => (this.searchLocal = value)}
            filter={this.filterLocal}
            onClearFilter={this.clearFilter}
          />
        )}

        <b-overlay show={this.pendingLocal}>
          <b-table-simple
            bordered
            responsive
            striped={this.striped}
            hover={this.hover}
            outlined
            class="wayup-table mb-0"
          >
            <Header
              columns={this.currentColumns}
              sort={this.sortLocal}
              on={{
                'update:sort': (sort: Sort<any> | null) =>
                  (this.sortLocal = sort),
              }}
            />

            <b-tbody class="position-relative" key={this.tableKey}>
              {this.displayedRows.map((row, rowIndex) => (
                <b-tr key={rowIndex * this._currentPage} class="wayup-row">
                  {this.values(row).map((_, index) => {
                    const cellData = this.cellData(row, index)
                    return (
                      <Cell
                        value={cellData.value}
                        row={cellData.row}
                        column={cellData.column}
                        key={rowIndex * this._currentPage + index}
                        class={{
                          'can-filter': this.currentColumns[index].filter,
                        }}
                        nativeOn={{
                          click: () => this.filterTable(cellData.row, index),
                        }}
                        scopedSlots={{
                          default: this.$scopedSlots.default,
                        }}
                      />
                    )
                  })}
                </b-tr>
              ))}
            </b-tbody>

            {this.footerRow && (
              <FooterRowComponent
                footerRow={this.footerRow!}
                columns={this.currentColumns}
                scopedSlots={{
                  default: this.$scopedSlots.footer,
                }}
              />
            )}

            {!this.rowsLocal.length && !this.pendingLocal && (
              <div class="empty-message">
                <span>Ничего не найдено =(</span>
              </div>
            )}
          </b-table-simple>
        </b-overlay>
        {this.paginationEnabled && (
          <Footer
            currentPage={this.currentPageLocal}
            perPage={this.perPageLocal}
            totalRows={this.totalRowsLocal}
            rowsCount={this.displayedRows.length}
            perPageOptions={this.perPageOptions}
            on={{
              'update:currentPage': (value: number) =>
                (this.currentPageLocal = value),
              'update:perPage': (value: number) => (this.perPageLocal = value),
            }}
          />
        )}
      </div>
    )
  }
}
