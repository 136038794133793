import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Cell from '../Cell'
import { CellData, Column, FooterRow } from '../types'
import './style.scoped.scss'

@Component
export default class FooterRowComponent extends tsx.Component<{
  footerRow: FooterRow
  columns: Column<any>[]
}> {
  declare $scopedSlots: tsx.InnerScopedSlots<{
    default?: CellData<any>
  }>

  @Prop({ type: Object, required: true })
  readonly footerRow!: FooterRow
  @Prop({ type: Array, required: true })
  readonly columns!: Column<any>[]

  protected render() {
    return (
      <b-tfoot>
        <b-tr>
          {this.columns.map(column => (
            <Cell
              column={column}
              row={this.footerRow}
              value={this.footerRow[column.field?.toString()!]}
              style="font-weight: bold;"
              scopedSlots={{
                default: this.$scopedSlots.default,
              }}
            />
          ))}
        </b-tr>
      </b-tfoot>
    )
  }
}
