import Switcher from '@/components/controls/Switcher'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import WayupPhoneInput from 'wayup-phone-input'
import { CellData, Column } from '../types'
import DateTimePicker from '@/components/controls/DateTimePicker'
import './style.scoped.scss'

@Component
export default class WayupTableCell extends tsx.Component<{
  value: any
  column: Column<any>
  row: any
}> {
  declare $scopedSlots: tsx.InnerScopedSlots<{ default?: CellData<any> }>

  @Ref() readonly phoneInput!: WayupPhoneInput

  @Prop({ required: true })
  readonly value!: any
  @Prop({ type: Object, required: true })
  readonly column!: Column<any>
  @Prop({ type: Object, required: true })
  readonly row!: any

  localValue: any = null
  validation: boolean | null = null
  saveTimeout: ReturnType<typeof setTimeout> | null = null
  messageTimeout: ReturnType<typeof setTimeout> | null = null
  needSave = false
  message = ''
  messageError = false
  uid = -1
  pending = false

  get centered() {
    return this.column.centered
  }

  get noWrap() {
    return this.column.noWrap
  }

  get displayedValue() {
    if (this.column.formatter) {
      return this.column.formatter(this.value, this.row)
    }

    return this.value
  }

  get dateTime() {
    return new Date(this.value).toLocaleString('ru', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      minute: '2-digit',
      hour: '2-digit',
    })
  }
  get date() {
    return new Date(this.value).toLocaleDateString('ru', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  get style() {
    if (typeof this.column.style === 'function') {
      return this.column.style({ value: this.value, row: this.row })
    }

    return this.column.style
  }

  @Watch('value')
  onValueChanged() {
    this.localValue = this.value
  }

  @Watch('pending')
  @Watch('message')
  onTooltipChanged(value: any) {
    if (value) {
      this.$nextTick(() => {
        const messageEl = document.getElementById(`message-${this.uid}`)
        const cellEl = document.getElementById(`cell-${this.uid}`)

        if (!messageEl || !cellEl) {
          return
        }

        document.body.appendChild(messageEl)

        const rect = cellEl.getBoundingClientRect()
        messageEl.style.top = `${rect.y + window.scrollY + rect.height}px`
        messageEl.style.left = `${rect.x + rect.width / 2}px`
      })
    }
  }

  mounted() {
    this.localValue = this.value
    this.uid = (this as any)._uid

    if (!this.column.editable && this.column.type === 'switcher') {
      throw new Error('Колонка с типом "switcher" должна быть редактируемой')
    }
  }

  async input(value: any) {
    if (value === this.value) {
      return
    }

    this.needSave = true

    this.localValue = value

    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout)
    }

    const delay =
      this.column.type === 'switcher' || this.column.type === 'date' ? 0 : 1000

    this.saveTimeout = setTimeout(() => {
      this.asyncSave(this.save)
    }, delay)

    if (
      this.column.editable &&
      typeof this.column.editable !== 'boolean' &&
      this.column.editable.inputEvent
    ) {
      const validation = await this.column.editable.inputEvent(value, this.row)
      if (typeof validation === 'boolean') {
        this.validation = validation
      } else {
        this.validation = null
      }
    }
  }

  async save() {
    if (!this.needSave || this.validation === false) {
      return
    }

    if (this.phoneInput?.validate() === false) {
      return
    }

    this.needSave = false
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout)
    }

    if (
      this.column.editable &&
      typeof this.column.editable !== 'boolean' &&
      this.column.editable.save
    ) {
      return await this.column.editable.save(this.localValue, this.row)
    }
  }

  showMessage(message: string, error = false) {
    if (this.messageTimeout) {
      clearTimeout(this.messageTimeout)
    }

    this.messageError = error
    this.message = message

    this.messageTimeout = setTimeout(() => {
      this.message = ''
    }, 1000)
  }

  async asyncSave(
    fn: Function,
    successMessage = 'Сохранено!',
    errorMessage = 'Ошибка!',
  ) {
    this.pending = true

    try {
      const result = await fn()

      if (result === true) {
        this.showMessage(successMessage)
      } else if (result === false) {
        this.showMessage(errorMessage, true)
      }
    } catch (error) {
      this.showMessage(errorMessage, true)
      console.error(error)
      this.validation = false
    }
    this.pending = false
  }

  get renderMessage() {
    if (!this.message && !this.pending) {
      return null
    }

    return (
      <transition name="fade">
        <div
          id={`message-${this.uid}`}
          class={[
            'cell-message',
            'shadow',
            { error: this.messageError },
            { pending: this.pending },
          ]}
        >
          {this.pending ? <b-spinner small /> : this.message}
        </div>
      </transition>
    )
  }

  protected render() {
    const cellClass = [
      'p-50',
      { centered: this.centered },
      { 'no-wrap': this.noWrap },
    ]

    if (this.$scopedSlots.default) {
      const renderedSlot = this.$scopedSlots.default({
        value: this.column.formatter
          ? this.column.formatter(this.value, this.row)
          : this.value,
        row: this.row,
        column: this.column,
        saveFn: this.asyncSave,
      })
      if (renderedSlot) {
        return (
          <b-td id={`cell-${this.uid}`} class={cellClass} style={this.style}>
            {renderedSlot}
            {this.renderMessage}
          </b-td>
        )
      }
    }

    if (this.column.editable) {
      switch (this.column.type) {
        case 'phone':
          return (
            <b-td id={`cell-${this.uid}`} class="p-0" style={this.style}>
              <WayupPhoneInput
                ref="phoneInput"
                value={this.value}
                onInput={this.input}
                onBlur={() => {
                  if (this.needSave) this.asyncSave(this.save)
                }}
                countryDetect={false}
                validateOnInput
                class="phone-input"
              />

              {this.renderMessage}
            </b-td>
          )
        case 'date':
          return (
            <b-td
              id={`cell-${this.uid}`}
              class="date-cell p-0"
              style={this.style}
            >
              <DateTimePicker
                value={this.value}
                onInput={this.input}
                dateFormat="Y-m-dTH:i:S"
                displayedFormat="d.m.Y"
                class="date-picker"
              />
              {this.renderMessage}
            </b-td>
          )

        case 'switcher':
          return (
            <b-td
              id={`cell-${this.uid}`}
              class="centered p-0"
              style={this.style}
            >
              <Switcher
                value={this.value}
                onInput={this.input}
                variant={this.column.switcherVariant}
                disabled={this.pending}
              />

              {this.renderMessage}
            </b-td>
          )
        default:
          return (
            <b-td id={`cell-${this.uid}`} class="p-0" style={this.style}>
              <b-form-input
                value={this.value}
                type={this.column.type === 'number' ? 'number' : 'text'}
                number={this.column.type === 'number'}
                state={this.validation}
                trim
                class={[
                  'cell-input',
                  { centered: this.centered },
                  { validation: this.validation !== null },
                ]}
                onInput={this.input}
                onBlur={() => {
                  if (this.needSave) this.asyncSave(this.save)
                }}
              />

              {this.renderMessage}
            </b-td>
          )
      }
    }

    switch (this.column.type) {
      case 'string':
      case 'number':
        return (
          <b-td class={cellClass} style={this.style}>
            {this.displayedValue}
          </b-td>
        )
      case 'dateTime':
        return (
          <b-td class={cellClass} style={this.style}>
            {this.dateTime}
          </b-td>
        )
      case 'date':
        return (
          <b-td class={cellClass} style={this.style}>
            {this.date}
          </b-td>
        )
      case 'email':
        return (
          <b-td class={cellClass} style={this.style}>
            <a href={`mailto:${this.value}`}>{this.value}</a>
          </b-td>
        )
      default:
        return (
          <b-td class={cellClass} style={this.style}>
            {typeof this.displayedValue === 'object' ||
            typeof this.displayedValue === 'boolean'
              ? JSON.stringify(this.displayedValue)
              : this.displayedValue}
          </b-td>
        )
    }
  }
}
