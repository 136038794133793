import { Component, PropSync, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import PageSelector from '@/components/WayupTable/Footer/PerPageSelector'
import Pagination from '@/components/WayupTable/Footer/Pagination'
import './style.scoped.scss'

interface IFooterProps {
  totalRows: number
  rowsCount: number
  currentPage: number
  perPage: number
  perPageOptions: number[]
}

@Component
export default class WayupTableFooter extends tsx.Component<IFooterProps> {
  @Prop({ type: Number, required: true })
  readonly totalRows!: number
  @Prop({ type: Number, required: true })
  readonly rowsCount!: number
  @Prop({ type: Array, default: () => [10, 20, 50, 100] })
  readonly perPageOptions!: number[]

  @PropSync('currentPage', { type: Number, default: 1 })
  _currentPage!: number

  @PropSync('perPage', { type: Number, default: 10 })
  _perPage!: number

  protected render() {
    return (
      <div class="table-footer d-flex align-items-center justify-content-between p-25 border">
        <PageSelector
          value={this._perPage}
          onInput={(value: number) => (this._perPage = value)}
          totalRows={this.totalRows}
          rowsCount={this.rowsCount}
          perPageOptions={this.perPageOptions}
        />
        <Pagination
          value={this._currentPage}
          onInput={(value: number) => (this._currentPage = value)}
          perPage={this._perPage}
          totalRows={this.totalRows}
        />
      </div>
    )
  }
}
