import { IVmodelProps } from '@/interfaces/IVmodelProps'
import { Component, VModel, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

export type SwitcherVariant = 'red-green'

interface ISwitcherProps extends IVmodelProps<boolean> {
  variant?: SwitcherVariant
  switcherId?: string
  disabled?: boolean
}

@Component
export default class Switcher extends tsx.Component<ISwitcherProps> {
  declare $scopedSlots: tsx.InnerScopedSlots<{
    leftIcon?: void
    rightIcon?: void
  }>

  @VModel()
  checked!: boolean

  @Prop({ type: String })
  readonly variant!: SwitcherVariant
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean
  @Prop({ type: String })
  readonly switcherId?: string

  protected render() {
    return (
      <div class={[{ 'red-green': this.variant === 'red-green' }]}>
        <b-form-checkbox
          id={this.switcherId}
          checked={this.checked}
          onInput={(value: boolean) => (this.checked = value)}
          disabled={this.disabled}
          switch
        >
          {this.$scopedSlots.leftIcon && (
            <span class="switch-icon-left">{this.$scopedSlots.leftIcon()}</span>
          )}
          {this.$scopedSlots.rightIcon && (
            <span class="switch-icon-right">
              {this.$scopedSlots.rightIcon()}
            </span>
          )}
          {this.$slots.default}
        </b-form-checkbox>
      </div>
    )
  }
}
